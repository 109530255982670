.box-animation-open{
	position: relative;
	overflow: hidden;
 }
 .box-animation{
	display: flex;
	flex-direction: column;
	justify-content: center;
	position: absolute;
	top: 100%;
	height: 100%;
	left: 0;
	right: 0;
	background: #333;
	padding: 30px;
	visibility: hidden;
	transition: 0.3s ease;
 }
 .box-animation-open:hover .box-animation{
	top: 0;
	visibility: inherit;
 }
 .box__animation-title{
	color: #e6e5e5;
	font-weight: bold;
	font-size: 26px;
	line-height: 1;
 }
 .box__animation-desc{
	color: #c1c7c9;
	line-height: 1.5;
	padding: 1rem 0;
 }
 .box__animation-link{
	color: #fff;
 }
 .box__animation-link:hover{
	text-decoration: underline;
 }