.CheckBox {
	position: relative;
	cursor: pointer;
	&.disabled {
		pointer-events: none;
		user-select: none;
		opacity: 0.3;
		color: #666;
	}
	&:not(:last-child) {
		margin-bottom: 12px;
	}
   input:checked + span {
		&::before {
			background-color: #5b6770;
		}
		&::after {
			opacity: 1;
			visibility: visible;
		}
   }
	span {
		&::before {
			content: "";
			position: absolute;
			left: 0;
			top: 10px;
			transform: translateY(-50%);
			width: 16px;
			height: 16px;
			border: 2px solid #5b6770;
		}
		&::after {
			content: '';
			position: absolute;
			left: 4px;
			top: 6px;
			transform: rotate(-45deg);
			width: 9px;
			height: 5px;
			background-color: #5b6770;
			transition: all 0.2s ease 0s;
			border-left: 2px solid #fff;
			border-bottom: 2px solid #fff;
			opacity: 0;
			visibility: hidden;
		}
	}
}
