.RadioButton {
	position: relative;
	cursor: pointer;
	&:not(:last-child) {
		margin-bottom: 12px;
	}
   input:checked + span {
		&::after {
			opacity: 1;
			visibility: visible;
		}
   }
	span {
		&::before {
			content: "";
			position: absolute;
			left: 0;
			top: 50%;
			transform: translateY(-50%);
			width: 16px;
			height: 16px;
			border-radius: 50%;
			border: 2px solid #5b6770;
		}
		&::after {
			content: '';
			position: absolute;
			left: 5px;
			top: 50%;
			transform: translateY(-50%);
			width: 6px;
			height: 6px;
			border-radius: 50%;
			background-color: #5b6770;
			opacity: 0;
			visibility: hidden;
			transition: all 0.2s ease 0s;
		}
	}
}
