.react-tabs__tab--selected{
	background-color: transparent;
	filter: grayscale(0);
}

.intelligent-tab.react-tabs__tab--selected {
	position: relative;
	&::before {
		content: '';
		width: 22px;
		height: 26px;
		position: absolute;
		top: 100%;
		left: 45%;
		background: url('https://images.jhtassets.com/e8ba32a2ed70d8b648bba3f4d439bd3bfdcf0328/') center / contain no-repeat;
	}
}