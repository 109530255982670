.consolesTouch-page{
    .section-2{
        background: url('https://images.jhtassets.com/85f0d2476e4256003f271cbe52c31844eb51516b/') no-repeat;
        background-size: cover;
        background-position: center;
    }
    .section-4 {
        background-size: cover;
        background-position: center;
    }
    .section-5{
        .react-tabs__tab--selected{
            position: relative;
            background: #000 !important;
            outline: none !important;
            &::before{
                content: '';
                position: absolute;
                right: 0;
                top: 0;
                height: 90%;
                width: 4px;
                background-color: #e1261c;
            }
        }
    }
    .section-6{
        background: url('https://images.jhtassets.com/5308450e4d726fc3b9495a0c00ce1af3abb0af31/') no-repeat;
        background-size: contain;
        background-position: bottom;

        .images > div{
            text-align: center;
            margin-bottom: 3rem;

            img{
                margin: 0 auto 12px auto;
            }
        }
    }
    .section-8{
        background: url('https://images.jhtassets.com/a81515dcdbb2835336ea7a33c9afb4ffac2d7585/') no-repeat;
        background-size: cover;
        background-position: center;
    }
    .section-10{
        background: url('https://images.jhtassets.com/23e34fa557752bb0b78cba405069663e597b5803/') no-repeat;
        background-size: cover;
        background-position: center;
    }
}