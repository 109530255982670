.CardBoxMoreImage {
	@media screen and (min-width: 1280px) {
		min-height: 500px;
	}
}

.CardBoxMoreImage-content {
	@media screen and (max-width: 1024px) {
		width: 100%!important;
	}
}