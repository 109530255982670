.consolesPremiumLed-page{
    .section-1{
        background: url('https://images.jhtassets.com/1b01b6dee855017195bffcd43d64b14fd4213e3b/') no-repeat;
        background-size: cover;
        background-position: center;
    }
    .section-3{
        .grid-1{
            height: 100vh;
            background: url('https://images.jhtassets.com/9ca02013e74534710091d5830139b261a4083295/') no-repeat;
            background-size: cover;
            background-position: center;
        }
        .grid-2 {
            height: 100vh;
            background: url('https://images.jhtassets.com/960a63d6ae4440f9b755544c4914122f2a41b9d3/') no-repeat;
            background-size: cover;
            background-position: center;
        }
    }
    .section-6 {
        background: url('https://images.jhtassets.com/a81515dcdbb2835336ea7a33c9afb4ffac2d7585/') no-repeat;
        background-size: cover;
        background-position: center;
    }
    .section-8 {
        background: url('https://images.jhtassets.com/5308450e4d726fc3b9495a0c00ce1af3abb0af31/') no-repeat;
        background-size: contain;
        background-position: bottom;
    }    
    .section-10 {
        background: url('https://images.jhtassets.com/23e34fa557752bb0b78cba405069663e597b5803/') no-repeat;
        background-size: cover;
        background-position: center;
    }
}