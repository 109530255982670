.contact-page{
    .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input {
        color: #666 !important;
        font-weight: bold;
    }

    .css-1yk1gt9-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root {
        border-radius: 0 !important;
    }

    .css-1yk1gt9-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root.Mui-focused .MuiOutlinedInput-notchedOutline {
        border-color: #000 !important;
    }

    .css-1sumxir-MuiFormLabel-root-MuiInputLabel-root.Mui-focused {
        color: #666 !important;
    }

    .css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root {
        border-radius: 0 !important;
    }

    .css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline{
        border-color: #000 !important;
    }
    .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input,.css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root{
        color: #666;
        font-weight: bold;
    }
    .css-1u3bzj6-MuiFormControl-root-MuiTextField-root {
        @media screen and (max-width: 640px) {
            width: 100%!important;
        }
    }
}
