.cardioConsoles-page{
    .section-1{
        height: 80vh;
        background: url('https://images.jhtassets.com/c33544630f595b8adbd8b420da889b5cb1d051c5/') no-repeat;
        background-size: cover;
        background-position: center;
    }
    .section-2{
        background: url('https://images.jhtassets.com/5308450e4d726fc3b9495a0c00ce1af3abb0af31/') no-repeat;
        background-position: bottom;
    }
    .section-4 {
        background: url('https://images.jhtassets.com/5308450e4d726fc3b9495a0c00ce1af3abb0af31/') no-repeat;
        background-position: center;
    }
    table{
        margin-top: 2rem;
        .table-titles{
            background: #5b6770;
            border: 1px solid #5b6770;
            color: #c2c6c9 !important;
        }
        th{
            padding: 1rem !important;
        }
        td{
            padding: 1rem !important;
            line-height: 1.5;
            text-align: center;
            color: #666 !important;
            border: 1px solid #dde0e2 !important;
            font-size: 15px;
        }
        tr td:first-child{
            background: #f5f5f5 !important;
            text-align: left !important;
            color: #000 !important;
            font-weight: bold;
        }
    }
    .section-6{
        background-size: cover;
        background-position: center;
    }
    .section-7{
        height: 80vh;
        background: url('https://images.jhtassets.com/361f1d1e4e0a9f7baa07356a32abc93ee459775a/') no-repeat;
        background-size: cover;
        background-position: center;
    }
}