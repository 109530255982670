.about-page {
    .about-header{
        height: 80vh;
        background: url('https://images.jhtassets.com/bf04641121373e8162cb7a4f266858f111e0447f/') no-repeat;
        background-size: cover;
        background-position: center;
    }
    .section-2{
        padding: 4rem 0;
        background: url('../images/matrix2.png') no-repeat;
        background-size: cover;
        background-position: center;
        @media screen and (min-width: 1024px) {
            padding: 10rem 0;    
        }
    }
    .section-3 {
        padding: 4rem 0;
        background: url('../images/matrix3.jpg') no-repeat;
        background-size: cover;
        background-position: center;
        clip-path: polygon(0 5vw, 100% 0, 100% calc(100% - 5vw), 0 100%);
        @media screen and (min-width: 1024px) {
            padding: 10rem 0;    
        }
    }
}