.BgImageSection {
	
	@media screen and (max-width: 1280px) {
		height: auto!important;
	}
}

.BgImageSection-content {
	@media screen and (max-width: 1280px) {
		width: 100%!important;
	}
}