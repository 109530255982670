#root {
	display: flex;
	flex-direction: column;
	height: 100%;
	width: 100%;
}

.button-red {
	color: #fff;
	background-color: #e1261c;
	border-color: #e1261c;
	outline: none;
	font-size: 22px;
}

.page-wrapper {
	flex: 1 1 auto;
}

.VideoPopupButton:hover > svg {
	fill: #fff;
}

body .swiper-3d .swiper-slide-shadow-left,
body .swiper-3d .swiper-slide-shadow-right {
	background-image: none;
}