.media-main-height{
    @media (max-width: 1024px){
        min-height: 67px !important;
        height: 67px !important;
    }
}
header{
    @media (max-width: 1024px){
        height: 67px;
    }

    .media-bg-white{
        @media (max-width: 1024px) {
            top: 67px !important;
        }
    }

    .header-search-icon{
        color: #5f6163;
    }
    .MuiInputBase-input.css-yz9k0d-MuiInputBase-input::placeholder{
        color: #c1c7cb !important;
    }
    .dropdown-menu{
        .menu-ul{
            border-right: 5px solid #000;

            >li {
                text-align: right;
                font-weight: bold;
                padding: 15px 20px;
                // margin-right: 2px;
                font-size: 13px;
                line-height: 1;
                @media (max-width: 1024px){
                    &.active {
                        .dropdown-title {
                            position: absolute !important;
                            right: 0px !important;
                            background: #e1261c !important;
                            color: #fff !important;
                            height: 100% !important;
                            width: 22% !important;
                            top: 0 !important;
                            text-align: center !important;
                            display: flex !important;
                            align-items: center !important;
                            justify-content: center !important;
                        }

                        .sub-dropdown {
                            @media (max-width: 1024px) {
                                display: block !important;
                                height: 100% !important;
                                margin-left: 0 !important;
                                margin-right: 22% !important;
                                padding-left: 1% !important;
                                padding-right: 0% !important;
                                left: 0 !important;
                                background: linear-gradient(180deg, #fff, #f0f1f1) !important;
                                overflow-y: hidden !important;
                                z-index: 10000000 !important;
                            }
                        }
                    }
                }
                @media (max-width: 1024px) {
                    font-size: 10px;
                }
                &:hover {
                    @media (min-width: 1025px){
                        background: #e1261c;
                        color: #fff;
                        cursor: pointer;
                        .sub-dropdown {
                            display: flex !important;
                            flex-direction: column;
                            z-index: 10 !important;
                        }
                    }
                }
            }

            .sub-dropdown{
                display: none !important;
                height: -webkit-fill-available;
                width: -webkit-fill-available;
                margin-left: 22%;
                padding-left: 3%;
                padding-right: 5%;
                padding-bottom: 1.5rem;

                .grid{
                    background: linear-gradient(180deg, #fff, #f0f1f1);
                    height: -webkit-fill-available;
                }

                label{
                    padding: 15px 20px;
                }

                li{
                    text-align: left;

                    a{
                        display: block;
                        font-size: 13px;
                        text-transform: uppercase;
                        font-weight: bold;
                        padding: 15px 20px;
                        text-align: left;
                        line-height: 1;
                        color: #000;
                        @media (max-width: 1024px) {
                            font-size: 10px;
                        }
                        &:hover{
                            background: #e1261c;
                            color: #fff;
                        }
                    }
                }
            }
        }
    }

    display: none;
}

.router-active{
    background: #e1261c !important;
    color: #fff !important;
}