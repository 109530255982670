@tailwind base;
@tailwind components;
@tailwind utilities;

html{
  padding: 0;
  margin: 0;
  height: 100%;
  width: 100%;
}
body{
  height: 100%;
  width: 100%;
  padding: 0;
  margin: 0;
  font-family: Arial, Helvetica, sans-serif;
  color: #191919;
}
*{
  font-family: Arial, Helvetica, sans-serif;
}
ul li{
  list-style-type: none;
}
a{
  text-decoration: none;
}
label{
  color: #666;
}
.header-menu .menu-link:hover{
  color: #fff !important;
}
.header-menu .menu-link.header-dropdown:hover {
  color: #000 !important;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
*{
  scroll-behavior: smooth !important;
}

/*
#c2c6c9
#4c4c4c
#e6e5e5
*/

.box-animation-open{
  position: relative;
  overflow: hidden;
}

@media screen and (max-width: 768px) {
  .box-animation-open img{
    height: 400px;
    width: 100%;
  }
}

.box-animation{
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: absolute;
  top: 100%;
  height: 100%;
  left: 0;
  right: 0;
  background: #333;
  padding: 30px;
  visibility: hidden;
  transition: 0.3s ease;
}
.box-animation-open:hover .box-animation{
  top: 0;
  visibility: inherit;
}
.box__animation-title{
  color: #e6e5e5;
  font-weight: bold;
  font-size: 26px;
  line-height: 1;
}
.box__animation-desc{
  color: #c1c7c9;
  line-height: 1.5;
  padding: 0.5rem 0 1rem 0;
}
.box__animation-link{
  color: #fff;
}
.box__animation-link:hover{
  text-decoration: underline;
}

/*  */

.center-box-animation{
  position: relative;
}
.center-box-absolute{
  background: rgba(0, 0, 0, .4);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 30%;
  bottom: 30%;
  width: 100%;
  text-align: center;
  left: 0;
  right: 0;
  z-index: 1;
  transition: border 0ms .15s,
    top 275ms ease-out,
    bottom 275ms ease-out;
}
.center-box-animation:hover .center-box-absolute {
  border: 4px solid #363636;
  top: 0;
  bottom: 0;
}
.center-box-title{
  color: #fff;
  text-align: center;
  text-transform: uppercase;
}
.center-box-subtitle{
  font-size: 25px;
  color: #fff;
  text-transform: uppercase;
  line-height: 1;
  margin: 8px 0;
}
.center-box-name{
  color: #fff;
  text-transform: uppercase;
}
/* MAIN MEDIA */
@media (max-width: 768px) {
  .main-media-height{
    height: auto !important;
  }
}
.css-oyp70u{
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}
.video-close-icon{
  z-index: 10 !important;
}