.supportFaq-page{
    .MuiButtonBase-root.MuiAccordionSummary-root.css-1sg6dgf-MuiButtonBase-root-MuiAccordionSummary-root:hover{
        background: #f5f5f5;
    }
    h4{
        color: #4c4c4c;
        font-weight: bold;
        line-height: 1.2;
        margin: 1rem 0 0.5rem 0;
        font-size: 21px;
    }
    p{
        color: #191919;
    }
}